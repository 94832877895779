import * as React from "react";

const arrayAverage = (array) => array.reduce((a, b) => a + b, 0) / array.length;

export const usePolygonCenter = (coordinates) => {
  const center = React.useMemo(() => {
    if (!coordinates || !coordinates.length) {
      return null;
    }

    const centerLatitude = arrayAverage(coordinates.map((c) => c.latitude));
    const centerLongitude = arrayAverage(coordinates.map((c) => c.longitude));

    if (Number.isNaN(centerLatitude) || Number.isNaN(centerLongitude)) {
      return null;
    }

    return { latitude: centerLatitude, longitude: centerLongitude };
  }, [coordinates]);

  return center;
};
