import * as React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

import { Layout } from "../../components/layout";
import { Seo } from "../../components/seo";
import { Button } from "../../components/button";
import { MapContainer } from "../../components/mapContainer";
import { InteractiveMapPlaceholder } from "../../components/interactiveMap/placeholder";
import { NearbyToiletsList } from "../../components/nearbyToiletsList";

import { useIsBrowser } from "../../hooks/useIsBrowser";
import { useToiletMarkers } from "../../hooks/useToiletMarkers";
import { usePolygonCenter } from "../../hooks/usePolygonCenter";

import * as classes from "./style.module.css";

const InteractiveMap = React.lazy(
  () => import("../../components/interactiveMap"),
);

const BoroughTemplate = ({
  pageContext: { borough, toilets, nextBorough, previousBorough },
  location: { pathname },
}) => {
  const isBrowser = useIsBrowser();
  const [shouldLoadMap, setShouldLoadMap] = React.useState(false);
  const markers = useToiletMarkers(toilets);
  const center = usePolygonCenter(markers);

  return (
    <Layout mainClassName={classes.root}>
      <Seo
        title={`Toilets in ${borough}`}
        path={pathname}
        description={`See the ${toilets.length} public toilets located in ${borough}, Berlin`}
      />
      <div className={classes.scrollCurtain} />
      <div className={classes.sticky}>
        <h1>Toilets in {borough}</h1>
        {!shouldLoadMap && (
          <Button
            className={classes.loadMapButton}
            onClick={() => setShouldLoadMap(true)}
          >
            show on map
          </Button>
        )}
        {shouldLoadMap && (
          <MapContainer className={classes.mapContainer}>
            {isBrowser && (
              <React.Suspense fallback={<InteractiveMapPlaceholder />}>
                <InteractiveMap markers={markers} center={center} />
              </React.Suspense>
            )}
          </MapContainer>
        )}
      </div>

      <NearbyToiletsList toilets={toilets} />

      <div className={classes.linksContainer}>
        {previousBorough && (
          <Link
            to={`/toilets/borough/${previousBorough}`}
            className={classes.previousBoroughLink}
          >{`Toilets in ${previousBorough}`}</Link>
        )}
        {nextBorough && (
          <Link
            to={`/toilets/borough/${nextBorough}`}
            className={classes.nextBoroughLink}
          >{`Toilets in ${nextBorough}`}</Link>
        )}
      </div>
    </Layout>
  );
};

BoroughTemplate.propTypes = {
  pageContext: PropTypes.shape({
    borough: PropTypes.string.isRequired,
    nextBorough: PropTypes.string,
    previousBorough: PropTypes.string,
    toilets: PropTypes.arrayOf(
      PropTypes.shape({
        lavatoryID: PropTypes.string,
        description: PropTypes.string,
        street: PropTypes.string,
        postalCode: PropTypes.number,
        longitude: PropTypes.number,
        latitude: PropTypes.number,
        isOwnedByWall: PropTypes.oneOf([0, 1]),
        isHandicappedAccessible: PropTypes.oneOf([0, 1]),
        price: PropTypes.number,
        canBePayedWithCoins: PropTypes.oneOf([0, 1]),
        canBePayedInApp: PropTypes.oneOf([0, 1]),
        canBePayedWithNFC: PropTypes.oneOf([0, 1]),
        hasChangingTable: PropTypes.oneOf([0, 1]),
        hasUrinal: PropTypes.oneOf([0, 1]),
      }),
    ),
  }),
};

export default BoroughTemplate;
